<template>
  <transition name="fade">
    <div v-if="show" class="fullscreen-loader">
      <div class="loader-content">
        <!-- Wave animation -->
        <div class="wave">
          <div v-for="(dot, index) in 5" :key="index" class="wave-dot" />
        </div>
        <!-- Loader text -->
        <p class="loader-text">
          {{ $t('Loading...') }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FullscreenLoader',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-anyrent: color(anyrent);
$color-gray: color(gray);
$color-partner: color(partner);

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Fullscreen overlay */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loader container */
.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Wave animation container */
.wave {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}
.wave-dot {
  width: 10px;
  height: 10px;
  margin: 0 3px;
  background-color: $color-partner;
  border-radius: 50%;
  animation: wave 1.3s infinite ease-in-out;
}
.wave-dot:nth-child(1) {
  animation-delay: 0.0s;
}
.wave-dot:nth-child(2) {
  animation-delay: 0.1s;
}
.wave-dot:nth-child(3) {
  animation-delay: 0.2s;
}
.wave-dot:nth-child(4) {
  animation-delay: 0.3s;
}
.wave-dot:nth-child(5) {
  animation-delay: 0.4s;
}

@keyframes wave {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-15px);
  }
}

.loader-text {
  color: $color-partner;
  font-size: 1rem;
  font-family: sans-serif;
  text-align: center;
  margin: 0;
}
</style>
